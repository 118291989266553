<template>
  <section>
    <div :class="{ 'form-header': true, 'form-header__tabs-included': tabs.length > 0 }">
      <div class="form-header__title">
        <slot name="form-title">
          <ModuleIcon class="form-header__icon" />
          {{ titleText }}
          {{ ` - ` }}
          {{ moduleName }}
        </slot>
      </div>
      <div class="form-tabs">
        <div v-for="(tab, index) in tabs" :key="`tab-${index}`">
          <div
            class="form-tabs__tab"
            :class="{
              'form-tabs__tab--active': index === activeTab,
              'form-tabs__tab--error': tab.error
            }"
            @click="tabClick(index)"
          >
            <slot name="tab-title" :index="index" :tab="tab">
              {{ `${index + 1}. ${tab.name}` }}
            </slot>
          </div>
        </div>
      </div>
      <div class="form-header__buttons">
        <slot name="header-buttons" />
      </div>
    </div>
    <div v-if="showHeaderInfo" class="form-header-info">
      <table>
        <tr>
          <th>{{ $t('moduleForm.moduleId') }}</th>
          <th>{{ $t('moduleForm.createdBy') }}</th>
          <th>{{ $t('moduleForm.modifiedBy') }}</th>
        </tr>
        <tr>
          <td>{{ module.id }}</td>
          <td>{{ createdBy }}</td>
          <td>{{ modifiedBy }}</td>
        </tr>
      </table>
    </div>
    <slot name="form-content">
      <div class="form-content">
        <div class="form-content__left">
          <slot name="left-column" />
        </div>
        <div class="form-content__separator">
          <div class="form-content__separator__line"/>
        </div>
        <div class="form-content__right">
          <slot name="right-column" />
        </div>
      </div>
    </slot>
    <slot name="form-footer" />
  </section>
</template>

<script>
import ModuleIcon from '@/assets/img/svg/modules.svg?inline'
import UserService from '@/services/user/UserService'

export default {
  name: 'ModuleForm',
  props: {
    moduleName: {
      type: String,
      required: false
    },
    module: {
      type: Object, // { id, createdBy, createdAt, modifiedBy, modifiedAt }
      required: false
    },
    showHeaderInfo: {
      type: Boolean,
      default: false
    },
    selectedTab: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      activeTab: this.selectedTab
    }
  },
  components: {
    ModuleIcon
  },
  computed: {
    createdBy () {
      if (!this.module.createdBy && !this.module.createdById) {
        return ''
      }
      return `${this.userNameById(this.module.createdBy ?? this.module.createdById)} (${this.prettyDateTime(this.module.createdAt)})`
    },
    modifiedBy () {
      if (!this.module.modifiedBy && !this.module.modifiedById) {
        return ''
      }
      return `${this.userNameById(this.module.modifiedBy ?? this.module.modifiedById)} (${this.prettyDateTime(this.module.modifiedAt)})`
    },
    titleText () {
      if (this.$router.currentRoute.path.endsWith('/new')) {
        return this.$t('moduleForm.createModule')
      }
      if (this.$router.currentRoute.path.includes('/edit')) {
        return this.$t('moduleForm.editModule')
      }
      return this.$t('moduleForm.viewModule')
    }
  },
  methods: {
    userNameById (id) {
      const user = this.$store.getters['user/userById'](id)
      if (user) {
        return UserService.getUserInfo(user)
      }
      return id
    },
    prettyDateTime (dateTime) {
      return dateTime ? this.$options.filters.prettyDateTime(dateTime) : ''
    },
    tabClick (index) {
      this.activeTab = index
      this.$emit('tab-change', index)
    }
  },
  watch: {
    selectedTab (tab) {
      this.activeTab = tab
    },
    tabs: {
      handler (tabs) {
        const firstErrorTabIndex = tabs.findIndex(tab => tab.error)
        if (firstErrorTabIndex > -1) {
          this.tabClick(firstErrorTabIndex)
        }
      },
      deep: true
    }
  }
}
</script>
<style scoped lang="scss">
.form-header {
  background-color: #FFFFFF;
  padding: rem(10px);
  margin-bottom: 1rem;
  &__icon {
    @include size(rem(22px));
    padding-top: rem(4px);
    fill: #6599fe;
  }
  &__title {
    color: #465674;
    font-size: rem(22px);
    font-weight: 400;
    float: left;
    text-align: left;
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    gap: rem(5px);
  }
  &__tabs-included {
    @include bp(0 7) {
      margin-bottom: rem(50px);
    }
  }
}
.form-header-info {
  background-color: #FFFFFF;
  padding: rem(10px);
  margin-bottom: 1rem;
  border-radius: rem(6px);
  td, th {
    font-size: rem(14px);
    padding-right: 1rem;
  }
  th {
    font-weight: 700;
    color: #465674;
  }
  td {
    color: #8490A7;
    font-weight: 400;
  }
}
.form-content {
  display: flex;
  margin-top: rem(35px);
  @include bp(0 7) {
    flex-wrap: wrap;
  }
  &__left {
    @include bp(0 7) {
      flex: 0 0 100%;
      margin-bottom: 1em;
    }
    @include bp(11) {
      flex: 0 0 49%;
    }
  }
  &__right {
    @include bp(0 7) {
      flex: 0 0 100%;
      margin-bottom: 1em;
    }
    @include bp(11) {
      flex: 0 0 49%;
      padding-right: rem(10px);
    }
  }
  &__separator {
    @include bp(0 7) {
      display: none;
    }
    flex: 0 0 2%;
    &__line {
      margin-left: rem(20px);
      margin-right: rem(20px);
      background: #D1DBE4;
      width: rem(1px);
      height: 100%;
    }
  }
}
.form-tabs {
  display: flex;
  gap: rem(16px);
  float: left;
  margin-left: rem(20px);
  &__tab {
    @include font(500 14px "Roboto");
    padding-top: rem(10px);
    color: #465674;
    cursor: pointer;
    position: relative;
    display: block;
    white-space: nowrap;
    &:hover:not(.form-tabs__tab--active) {
      color: #000;
    }
    &--active {
      color: #6599FE;
      &::after {
        position: absolute;
        left: 0;
        bottom: rem(-13px);
        width: 100%;
        height: rem(3px);
        background: #6599FE;
        content: '';
        z-index: 1;
      }
    }
    &--error {
      color: #FF3455 !important;
    }
  }
}
</style>
